<template>
  <div class="Awarp">
    <dashCard
      class="powerBox26"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('powerBox26_distortionRate','电流畸变率', getZEdata)}}</template>
      <template slot="aside">
        <div class="type-group">
          <span
            class="pick-type"
            v-for="(item, idx) in timetType"
            :key="idx"
            @click="changeType(item.code)"
          >
            <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
              item.name
            }}</font>
          </span>
        </div>
        <a-date-picker
          v-if="searchObj.TYPE == '按天'"
          :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-week-picker
          v-if="searchObj.TYPE == '按周'"
          :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-month-picker
          v-if="searchObj.TYPE == '按月'"
          :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-date-picker
          v-if="searchObj.TYPE == '按年'"
          :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
          mode="year"
          :open="panelOpen"
          @openChange="openChange"
          @panelChange="panelChange"
          format="YYYY"
        />
      </template>
      <div class="card-content1">
        <a-select
          v-if="searchObj.ITEM1.length == 1"
          v-model="searchObj['ITEM']"
          style="width: 300px; margin-right: 16px; overflow: auto"
          @change="handleChange"
          mode="multiple"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <!-- :style="{'width': nameLength == true ? '100%':'300px' }" -->
          <a-select-option
            v-for="(item, key) in dbData"
            :key="key"
            :value="item.code"
            :disabled="
              searchObj['ITEM'].length >= 6 &&
              searchObj['ITEM'].findIndex((value) => value === item.code) === -1
            "
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-if="searchObj.ITEM1.length == 2"
          v-model="searchObj['ITEM']"
          style="width: 300px; margin-right: 16px; height: 32px"
          @change="handleChange"
          mode="multiple"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in dbData"
            :key="key"
            :value="item.code"
            :disabled="
              searchObj['ITEM'].length >= 3 &&
              searchObj['ITEM'].findIndex((value) => value === item.code) === -1
            "
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-if="searchObj.ITEM1.length == 3"
          v-model="searchObj['ITEM']"
          style="width: 300px; margin-right: 16px; height: 32px"
          @change="handleChange"
          mode="multiple"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in dbData"
            :key="key"
            :value="item.code"
            :disabled="
              searchObj['ITEM'].length >= 2 &&
              searchObj['ITEM'].findIndex((value) => value === item.code) === -1
            "
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-if="searchObj.ITEM1.length == 0"
          v-model="searchObj['ITEM']"
          style="width: 300px; margin-right: 16px; height: 32px"
          @change="handleChange"
          mode="multiple"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in dbData"
            :key="key"
            :value="item.code"
            :disabled="
              searchObj['ITEM'].length >= 3 &&
              searchObj['ITEM'].findIndex((value) => value === item.code) === -1
            "
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="searchObj['ITEM1']"
          style="width: 300px; height: 32px"
          @change="handleChange"
          @deselect="deselectChange($event, 'ITEM1')"
          mode="multiple"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in itemList"
            :key="key"
            :value="item.code"
            :disabled="
              searchObj['ITEM1'].length >= 2 &&
              searchObj['ITEM1'].findIndex((value) => value === item.code) ===
                -1
            "
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="card-content2">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <powerBox26
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "powerBox26",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      gridLeft: [],
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
       
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      itemList: [
        {
          code: "Max THD Current L1",
          name: "A相",
        },
        {
          code: "Max THD Current L2",
          name: "B相",
        },
        {
          code: "Max THD Current L3",
          name: "C相",
        },
      ],
      item1List: [
        {
          code: "1#主变",
          name: "1#主变",
        },
        {
          code: "2#主变",
          name: "2#主变",
        },
        {
          code: "3#主变",
          name: "3#主变",
        },
        {
          code: "4#主变",
          name: "4#主变",
        },
        {
          code: "5#主变",
          name: "5#主变",
        },
        {
          code: "6#主变",
          name: "6#主变",
        },
      ],
      panelOpen: false,
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
        ITEM: [], //选中的项目
        ITEM1: [
          "Max THD Current L1",
          "Max THD Current L2",
          "Max THD Current L3",
        ], //A相,B相,C相
        // ITEM: ['A相'],//选中的项目
        // ITEM1: ['1#主变'],//选中的项目
      },
      nameLength: false,
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      colorList: [
        "#7CB5EC",
        "#B0E2FF",
        "#444449",
        "#696969",
        "#90ED7D",
        "#98FB98",
      ],
    };
  },
  computed: {
    // 当前登录用户信息
    currentuserinfo() {
      return this.$store.state.currentuserinfo;
    },
    //dashboard数据字典
    dashboardOptions() {
      return this.$store.state.dashboardOptions;
    },
    assetDic() {
      return this.dashboardOptions.assetDic;
    },
    electricDic() {
      return this.dashboardOptions.electricDic;
    },
    dbData() {
      return this.$store.state.dbData;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      //console.log("onChange", value, this.searchObj);
      this.getData();
    },
    // 切换项目
    handleChange(value) {
      // //console.log(value);
      let reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      this.dbData.forEach((item) => {
        if (value === item.code) {
          if (reg.test(item.name) && item.name.length > 8) {
            this.nameLength = true;
          } else if (item.name.length > 5) {
            //console.log(111111);
            this.nameLength = true;
          }
        }
      });
      this.getData();
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          queryType: "day15m",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          queryType: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          queryType: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          queryType: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },

    //每月报警预警数
    getData() {
      const url = Config.dev_url + "/api-apps-v2/api/v2/energy/quality/distortion_rate";
      var data = {
        assets: this.searchObj.ITEM,
        variableNames: this.searchObj.ITEM1,
      };
      Object.assign(data, this.transformSearch());
      this.isLoading = true;
      this.$axios
        .post(url, data)
        .then((res) => {
          if (res.data.code == 0) {
            this.detailInfo = {};
            var chart1 = {
              categoryData: res.data.data.categoryData,
              seriesData: [],
            };
            res.data.data.seriesData.forEach((item) => {
              var obj = {
                name: item.variableName,
                value: item.avg,
                machineName: item.machineName,
              };

              chart1.seriesData.push(obj);
            });
            chart1.seriesData.forEach((item) => {
              this.itemList.forEach((obj) => {
                if (item.name === obj.code) {
                  item.name = item.machineName + "   " + obj.name;
                }
              });
            });
            // //console.log(this.itemList);
            this.detailInfo.chart1 = chart1;
            let newarr = [];
            this.detailInfo.chart1.seriesData.forEach((item) => {
              item.value.forEach((val) => {
                newarr.push(val);
              });
            });
            if (this.getNull(newarr) == newarr.length) {
              this.gridLeft = [0, 0, 0, 55];
            } else {
              this.gridLeft = [0, 0, 0, 0];
            }
            let lang = sessionStorage.getItem("locale") || 'CH'
            if(lang=='EN'){
              this.gridLeft = [0, 0, 0, 120];
            }
            this.initEchart(); //调用统计图
          } else {
            // this.$message.warning(res.data.msg)
          }
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getNull(newarr) {
      return newarr.reduce((total, currentValue) => {
        if (
          currentValue === null ||
          JSON.parse(currentValue) === null ||
          typeof currentValue == "Null"
        )
          return total + 1;
        return total;
      }, 0);
    },
    getData0() {
      this.detailInfo = {};
      var chart1 = {
        categoryData: [
          "8:01",
          "8:02",
          "8:03",
          "8:04",
          "8:05",
          "8:06",
          "8:07",
          "8:08",
          "8:09",
          "8:10",
          "8:11",
          "8:12",
        ],
        seriesData: [
          {
            name: "1#主变",
            value: [38, 39, 35, 29, 41, 43, 30, 29, 35, 36, 36, 32],
          },
          {
            name: "2#主变",
            value: [35, 36, 37, 38, 35, 34, 29, 32, 30, 29, 32, 34],
          },
          {
            name: "3#主变",
            value: [35, 36, 37, 33, 37, 35, 29, 32, 30, 29, 32, 34],
          },
          {
            name: "4#主变",
            value: [32, 31, 32, 35, 34, 38, 39, 22, 20, 39, 22, 24],
          },
          {
            name: "5#主变",
            value: [31, 36, 38, 31, 33, 37, 31, 33, 28, 29, 33, 36],
          },
        ],
      };
      this.detailInfo.chart1 = chart1;
      this.initEchart();
    },
    initEchart() {
      var { categoryData, seriesData } = this.detailInfo.chart1;

      var series = [];
      seriesData.forEach((item, idx) => {
        var obj = {
          type: "line",
          name: item.name,
          data: item.value,
          animationDurationUpdate: 2000,
          animationEasingUpdate: "quinticInOut",
          smooth: false,
          emphasis: {
            focus: "series",
          },
          symbol: "circle",
        };

        series.push(obj);
      });
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "1%",
          right: "2%",
          bottom: "7%",
          top: "15%",
          containLabel: true,
        },
        legend: {
          right: 0,
          top: "0%",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#1d2129",
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            // //console.log(params);
            let str = "";
            str += params[0].name + "<br>";
            params.forEach((item) => {
              str += `<div><span style="display:inline-block;width:10px;height:10px;background-color:${item.color};margin-right:4px;"></span><span style="display:inline-block;margin-left:10px;">${item.seriesName}:${item.value}</span></div>`;
            });
            return str;
          },
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            height: 20,
            bottom: 0,
          },
          {
            start: 0,
            end: 100,
            height: 20,
            bottom: 10,
          },
        ],
        xAxis: {
          type: "category",
          data: categoryData,
          // boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
        },
        yAxis: {
          name: this.commonLangHandler('powerBox26_aberrationRate','畸变率', this.getZEdata) + '(%)',
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
            padding: this.gridLeft,
          },
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
          splitLine: {
            show: true,
            lineStyle: {
              // type: "dashed",
              width: 0.5,
              color: "#bababa",
            },
          },
          // 控制数据参数最大值和最小值
          // interval: 10,
          // max: 100
        },

        // 控住柱状图样式
        series: series,
        color: this.colorList,
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.getOperateWidth();
    let lang = sessionStorage.getItem("locale") || 'CH'
    if(lang=='EN'){
      
        for(let item of this.itemList){
          if(item.name =='A相' ){
            item.name = 'Phase A'
          }
          if(item.name =='B相' ){
            item.name = 'Phase B'
          }
          if(item.name =='C相' ){
            item.name = 'Phase C'
          }
        }
    }
    if (!this.option.visible) {
      if (this.dbData.length > 0) {
        this.searchObj.ITEM = [this.dbData[0].code];
      }
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.powerBox26 {
  .card-content1 {
    display: flex;
    height: 82px;
    .content-pass {
      margin-top: 12px;
      div {
        &:nth-child(1) {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
        }
        &:nth-child(2) {
          font-size: 32px;
          font-weight: 500;
          color: #1d2129;
          line-height: 40px;
          font {
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);
            line-height: 20px;
            margin-left: 6px;
          }
        }
      }
    }
  }
  .card-content2 {
    flex: 1;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
